import React from 'react';
import './App.css';
import FormulaPlayerCardJs from './formula-d-player-card-js';

function App() {
  return (
      <div className="App">
        <FormulaPlayerCardJs />
      </div>
  );
}

export default App;