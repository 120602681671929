// ./components/ui.js

import React from 'react';

export const Select = ({ value, onChange, children }) => (
    <select value={value} onChange={onChange} className="form-select">
        {children}
    </select>
);

export const Button = ({ onClick, children, className }) => (
    <button onClick={onClick} className={`btn ${className}`}>
        {children}
    </button>
);

export const Card = ({ children, className }) => (
    <div className={`card ${className}`}>
        {children}
    </div>
);

export const CardContent = ({ children }) => (
    <div className="card-content">
        {children}
    </div>
);

export const CardHeader = ({ children }) => (
    <div className="card-header">
        {children}
    </div>
);

export const Switch = ({ checked, onChange }) => (
    <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round"></span>
    </label>
);

export const Input = ({ type = "text", value, onChange, className, min, max }) => (
    <input
        type={type}
        value={value}
        onChange={onChange}
        className={className}
        min={min}
        max={max}
    />
);
