import React, { useEffect, useRef, useState } from 'react';
import { Settings } from 'lucide-react';
import { Select, Button, Card, CardContent, CardHeader, Switch, Input } from './components/ui';
import sound  from './audio/roll-dice.mp3';
import gearAudio from './audio/gear-change.mp3';
const gearSound = new Audio(gearAudio);
const diceSound = new Audio(sound)
const FormulaPlayerCard = () => {

    const translations = {
        en: {
            gameMode: "Rules :",
            basic: "Basic",
            advanced: "Advanced",
            carState: "Car state",
            settings: "Settings",
            currentGear: "Current gear",
            tires: "Tires",
            gearbox: "Gearbox",
            engine: "Engine",
            brakes: "Brakes",
            body: "Body",
            handling: "Handling",
            language: "Langue",
            DriverName: "Driver Name",
            carColor: "Vehicle color",
            use7: "7 speed gearbox",
            dicesVal: "Dices values",
            yellow: "Yellow",
            orange: "Orange",
            red: "Red",
            green: "Green",
            purple: "Purple",
            blue: "Blue",
            black: "Black",
            cyan: "Brown",
            psMax: "Max WPs",
            vehicle: "Vehicle",
            roll: "ROLL",
            reset: "Reset all settings",
            resetConfirmation: "Are you sure you want to reset all settings?",
            to: "to",
            audio: "Audio",
        },
        fr: {
            gameMode: "Règles :",
            basic: "De base",
            advanced: "Complètes",
            carState: "État de la voiture",
            settings: "Paramètres",
            currentGear: "Vitesse enclenchée",
            tires: "Pneus",
            gearbox: "Boîte de vitesse",
            engine: "Moteur",
            brakes: "Freins",
            body: "Carrosserie",
            handling: "Tenue de route",
            language: "Language",
            DriverName: "Nom de pilote",
            carColor: "Couleur du véhicule",
            use7: "Boîte 7 vitesses",
            dicesVal: "Valeurs des dés",
            yellow: "Jaune",
            orange: "Orange",
            red: "Rouge",
            green: "Vert",
            purple: "Violet",
            blue: "Bleu",
            black: "Noir",
            cyan: "Marron",
            psMax: "PS maximum",
            vehicle: "Voiture",
            roll: "LANCER",
            reset: "Réinitialiser les paramètres",
            resetConfirmation: "Êtes-vous sûr·e de vouloir réinitialiser tous les paramètres ?",
            to: "à",
            audio: "Effets sonores",
        },
    };

    const rollDiceSound = new Audio('./audio/dice-roll.mp3');

    const [rotatingDice, setRotatingDice] = useState(null); // Track which dice is rotating
    const translate = (key) => {
        return translations[language][key] || key; // Fallback to the key itself if not found
    };

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('language') || 'fr'; // Default to 'fr' if nothing is saved
    });
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    const [gameMode, setGameMode] = useState(() => {
        return localStorage.getItem('gameMode') || 'simple';
    });
    useEffect(() => {
        localStorage.setItem('gameMode', gameMode);
    }, [gameMode]);

    const [speed, setSpeed] = useState(() => {
        return Number(localStorage.getItem('speed')) || 1;
    });
    useEffect(() => {
        localStorage.setItem('speed', speed);
    }, [speed]);

    const [vehicleColor, setVehicleColor] = useState(() => {
        return localStorage.getItem('vehicleColor') || 'green';
    });
    useEffect(() => {
        localStorage.setItem('vehicleColor', vehicleColor);
    }, [vehicleColor]);


    const [showSettings, setShowSettings] = useState(() => {
        const savedValue = localStorage.getItem('showSettings');
        return savedValue === 'true' ? true : false; // Convert stored string back to boolean
    });

    // Save showSettings to localStorage when it changes
    useEffect(() => {
        localStorage.setItem('showSettings', showSettings); // Save as a string
        // Scroll to the settings section if it is shown
        if (showSettings && settingsRef.current) {
            settingsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [showSettings]);

    // Create a ref for the settings section
    const settingsRef = useRef(null);


    const [driverName, setDriverName] = useState(() => {
        return localStorage.getItem('driverName') || 'Scott Speed';
    });
    useEffect(() => {
        localStorage.setItem('driverName', driverName);
    }, [driverName]);

    const [useSeventhGear, setUseSeventhGear] = useState(() => {
        const savedValue = localStorage.getItem('useSeventhGear');
        return savedValue === 'true' ? true : false; // Convert stored string back to boolean
    });


    useEffect(() => {
        localStorage.setItem('useSeventhGear', useSeventhGear); // Save as a string
    }, [useSeventhGear]);

    const [audioOn, setAudioOn] = useState(() => {
        const savedValue = localStorage.getItem('audioOn');
        return savedValue === 'true' ? true : false; // Convert stored string back to boolean
    });


    useEffect(() => {
        localStorage.setItem('audioOn', audioOn); // Save as a string
    }, [audioOn]);

  //const [useSeventhGear, setUseSeventhGear] = useState(false);
    // Initialize diceRanges from localStorage or use default values
    const [diceRanges, setDiceRanges] = useState(() => {
        const savedDiceRanges = localStorage.getItem('diceRanges');
        return savedDiceRanges
            ? JSON.parse(savedDiceRanges)
            : {
                yellow: { min: 1, max: 2 },
                orange: { min: 2, max: 4 },
                red: { min: 4, max: 8 },
                green: { min: 7, max: 12 },
                purple: { min: 11, max: 20 },
                blue: { min: 21, max: 30 },
                black: { min: 1, max: 20 },
                cyan: { min: 31, max: 38 },
            };
    });

    // Save diceRanges to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('diceRanges', JSON.stringify(diceRanges));
    }, [diceRanges]);

    // Store both simple and advanced carState and maxValues separately
    const [carState, setCarState] = useState(() => {
        const savedCarState = JSON.parse(localStorage.getItem('carState')) || {
            simple: { general: 18 },
            advanced: {
                tires: 14,
                brakes: 7,
                gearbox: 7,
                body: 7,
                engine: 7,
                handling: 7,
            }
        };
        return savedCarState;
    });

    const [maxValues, setMaxValues] = useState(() => {
        const savedMaxValues = JSON.parse(localStorage.getItem('maxValues')) || {
            simple: { general: 18 },
            advanced: {
                tires: 14,
                brakes: 7,
                gearbox: 7,
                body: 7,
                engine: 7,
                handling: 7,
            }
        };
        return savedMaxValues;
    });

    // Save carState and maxValues for both simple and advanced to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('carState', JSON.stringify(carState));
    }, [carState]);

    useEffect(() => {
        localStorage.setItem('maxValues', JSON.stringify(maxValues));
    }, [maxValues]);

    // Effect to update carState and maxValues when gameMode changes
    // useEffect(() => {
    //     const savedCarState = localStorage.getItem('carState');
    //     const savedMaxValues = localStorage.getItem('maxValues');
    //
    //     if (gameMode === 'simple') {
    //         if (!savedCarState) {
    //             setCarState({ general: 18 });
    //         } else {
    //             setCarState((prevState) => ({
    //                 ...prevState,
    //                 general: prevState.general || 18,
    //             }));
    //         }
    //         if (!savedMaxValues) {
    //             setMaxValues({ general: 18 });
    //         } else {
    //             setMaxValues({
    //                 tires: 14,
    //                 brakes: 7,
    //                 gearbox: 7,
    //                 body: 7,
    //                 engine: 7,
    //                 handling: 7,
    //             });
    //         }
    //     } else {
    //         // Switching to advanced mode
    //         if (!savedCarState) {
    //             setCarState({
    //                 tires: 14,
    //                 brakes: 7,
    //                 gearbox: 7,
    //                 body: 7,
    //                 engine: 7,
    //                 handling: 7,
    //             });
    //         } else {
    //             // Ensure advanced attributes are retained
    //             setCarState((prevState) => ({
    //                 tires: prevState.tires || 14,
    //                 brakes: prevState.brakes || 7,
    //                 gearbox: prevState.gearbox || 7,
    //                 body: prevState.body || 7,
    //                 engine: prevState.engine || 7,
    //                 handling: prevState.handling || 7,
    //             }));
    //         }
    //         if (!savedMaxValues) {
    //             setMaxValues({
    //                 tires: 14,
    //                 brakes: 7,
    //                 gearbox: 7,
    //                 body: 7,
    //                 engine: 7,
    //                 handling: 7,
    //             });
    //         }
    //     }
    // }, [gameMode]);


// Handle car state change and max values for the current mode
    const handleMaxChange = (component, value) => {
        const newMaxValues = { ...maxValues };
        //newMaxValues[gameMode][component] = Math.max(1, value); // Ensure minimum is 1
        newMaxValues[gameMode][component] = value // Ensure minimum is 1
        setMaxValues(newMaxValues);

        // Update carState to automatically set current state to new max
        const newCarState = { ...carState };
        newCarState[gameMode][component] = newMaxValues[gameMode][component]; // Automatically set to the new max value
        setCarState(newCarState);
    };


    const resetApp = () => {
        if (window.confirm(translate('resetConfirmation'))) {
            localStorage.clear(); // Clear all localStorage data
            window.location.reload(); // Force a page reload
        }
    }






    const incrementCarState = () => {
        setCarState(prevState => ({
            ...prevState,
            general: Math.min(maxValues.general, prevState.general + 1), // Cap to max
        }));
    };

    const decrementCarState = () => {
        setCarState(prevState => ({
            ...prevState,
            general: Math.max(0, prevState.general - 1), // Set min to 0
        }));
    };


    const diceColors = ['yellow', 'orange', 'red', 'green', 'purple', 'blue', 'black'];
  if (useSeventhGear) diceColors.push('cyan');

  const vehicleColors = ['green', 'white', 'yellow', 'red', 'blue'];

  const [diceResult, setDiceResult] = useState(null); // Add a state to store the dice result

    const changeGear = (gear) => {
        setSpeed(gear);
        if(audioOn) {
            // Play the dice roll sound
            gearSound.currentTime = 0; // Reset sound to start
            gearSound.play();
        }
    }
    const rollDice = (color) => {
        const selectedDice = diceRanges[color]; // Get the selected dice ranges
        const { min, max } = selectedDice;

        setRotatingDice(color);

        if(audioOn) {
            // Play the dice roll sound
            diceSound.currentTime = 0; // Reset sound to start
            diceSound.play();
        }

        let result;

        // Use a switch statement to handle each die type
        switch (color) {
            case 'yellow':
                // Yellow Die: Check if min/max have been modified
                if (min === 1 && max === 2) {
                    const yellowOutcomes = [1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2]; // Total 12 outcomes
                    result = yellowOutcomes[Math.floor(Math.random() * yellowOutcomes.length)];
                } else {
                    result = Math.floor(Math.random() * (max - min + 1)) + min; // Random roll
                }
                break;

            case 'orange':
                // Orange Die: Check if min/max have been modified
                if (min === 2 && max === 4) {
                    const orangeOutcomes = [2, 3, 3, 4, 4, 4]; // Original distribution
                    result = orangeOutcomes[Math.floor(Math.random() * orangeOutcomes.length)];
                } else {
                    // Dynamic distribution based on min/max
                    const orangeOutcomes = [];

                    // Minimum value: 1 occurrence
                    orangeOutcomes.push(min);

                    // Next value (min + 1): 2 occurrences
                    if (min + 1 <= max) {
                        orangeOutcomes.push(min + 1, min + 1);
                    }

                    // All subsequent values: 3 occurrences
                    for (let value = min + 2; value <= max; value++) {
                        orangeOutcomes.push(value, value, value);
                    }

                    result = orangeOutcomes[Math.floor(Math.random() * orangeOutcomes.length)];
                }
                break;



            case 'red':
                // Red Die: Check if min/max have been modified
                if (min === 4 && max === 8) {
                    const redOutcomes = [4, 5, 6, 6, 7, 7, 8, 8]; // Original distribution
                    console.log(redOutcomes)
                    result = redOutcomes[Math.floor(Math.random() * redOutcomes.length)];
                } else {
                    // Dynamic distribution based on min/max
                    const redOutcomes = [];

                    // Minimum value: 1 occurrence
                    redOutcomes.push(min);

                    // Next value (min + 1): 1 occurrences
                    if (min + 1 <= max) {
                        redOutcomes.push(min + 1);
                    }

                    // All subsequent values: 2 occurrences
                    for (let value = min + 2; value <= max; value++) {
                        redOutcomes.push(value, value);
                    }
                    console.log(redOutcomes)
                    result = redOutcomes[Math.floor(Math.random() * redOutcomes.length)];
                }
                break;

            case 'green':
                // Green Die: Implement logic for green die
                if (min === 7 && max === 12) {
                    const greenOutcomes = [7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12]; // Example distribution
                    result = greenOutcomes[Math.floor(Math.random() * greenOutcomes.length)];
                } else {
                    result = Math.floor(Math.random() * (max - min + 1)) + min; // Random roll
                }
                break;

            case 'purple':
                // Purple Die: Implement logic for purple die
                if (min === 11 && max === 20) {
                    const purpleOutcomes = [11, 11, 12, 12, 13, 13, 14, 14, 15, 15, 16, 16, 17, 17, 18, 18, 19, 19, 20, 20]; // Uniform distribution
                    result = purpleOutcomes[Math.floor(Math.random() * purpleOutcomes.length)];
                } else {
                    result = Math.floor(Math.random() * (max - min + 1)) + min; // Random roll
                }
                break;

            case 'blue':
                // Blue Die: Implement logic for blue die
                if (min === 21 && max === 30) {
                    const blueOutcomes = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]; // Uniform distribution
                    result = blueOutcomes[Math.floor(Math.random() * blueOutcomes.length)];
                } else {
                    result = Math.floor(Math.random() * (max - min + 1)) + min; // Random roll
                }
                break;

            case 'black':
                // Black Die: Implement logic for black die
                if (min === 1 && max === 20) {
                    const blackOutcomes = Array.from({ length: 20 }, (_, i) => i + 1); // Values 1-20
                    result = blackOutcomes[Math.floor(Math.random() * blackOutcomes.length)];
                } else {
                    result = Math.floor(Math.random() * (max - min + 1)) + min; // Random roll
                }
                break;

            case 'cyan':
                // Black Die: Implement logic for 7 die
                    result = Math.floor(Math.random() * (max - min + 1)) + min; // Random roll
                break;

            default:
                result = null; // Handle unrecognized dice colors
        }

        // Trigger animation reset
        const diceResultElement = document.getElementById('dice-result');
        if (diceResultElement) {
            diceResultElement.classList.remove('die-roll-box');
            void diceResultElement.offsetWidth; // Trigger reflow to reapply animation
            diceResultElement.classList.add('die-roll-box');
        }

        // Stop the rotation animation after 500 ms
        setTimeout(() => {
            setRotatingDice(null); // Reset rotation after animation
        }, 500); // Match this duration with your CSS animation duration

        setDiceResult({ result, color }); // Save both the result and color for styling
    };

  const updateCarState = (key, value) => {
    setCarState(prevState => ({ ...prevState, [key]: value }));
  };



    const renderCarState = () => {
        if (gameMode === 'simple') {
            return (
                <div className="mt-4">
                    <label className="text-sm font-medium text-gray-600">{translate("carState")} (0-{maxValues.simple.general}): </label>
                    <div className="flex items-center mb-2">
                        {/* Decrement button */}
                        <button
                            onClick={() =>
                                setCarState((prevState) => ({
                                    ...prevState,
                                    simple: { general: Math.max(0, prevState.simple.general - 1) },
                                }))
                            }
                            className="px-2 py-1 border border-gray-300 rounded-md mr-2"
                        >
                            -
                        </button>

                        {/* Gradient-filled bar */}
                        <div
                            className="relative w-full h-6 rounded-md border border-black"
                            style={{
                                background: `#172554`,
                                overflow: 'hidden',
                            }}
                        >
                            {/* Background for the empty bar */}
                            <div
                                className="absolute inset-0 bg-gray-600"
                                style={{
                                    width: '100%',
                                }}
                            />
                            {/* Filled gradient according to the value */}
                            <div
                                className="absolute inset-0"
                                style={{
                                    background: `#172554`,
                                    width: `${(carState.simple.general / maxValues.simple.general) * 100}%`,
                                }}
                            />
                            {/* Display the current value */}
                            <span className="absolute inset-0 flex items-center justify-center font-bold text-white">
                            {carState.simple.general}
                        </span>
                        </div>

                        {/* Increment button */}
                        <button
                            onClick={() =>
                                setCarState((prevState) => ({
                                    ...prevState,
                                    simple: {
                                        general: Math.min(maxValues.simple.general, prevState.simple.general + 1),
                                    },
                                }))
                            }
                            className="px-2 py-1 border border-gray-300 rounded-md ml-2"
                        >
                            +
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mt-4 grid grid-cols-2 gap-2">
                    {Object.entries(carState.advanced).map(([key, value]) => (
                        <div key={key}>
                            <label className="text-sm font-medium text-gray-600">{translate(key)} </label>
                            <div className="flex items-center mb-2">
                                {/* Decrement button */}
                                <button
                                    onClick={() =>
                                        setCarState((prevState) => ({
                                            ...prevState,
                                            advanced: {
                                                ...prevState.advanced,
                                                [key]: Math.max(0, prevState.advanced[key] - 1),
                                            },
                                        }))
                                    }
                                    className="px-2 py-1 border border-gray-300 rounded-md mr-2"
                                >
                                    -
                                </button>

                                {/* Gradient-filled bar */}
                                <div
                                    className="relative w-full h-6 rounded-md border border-black"
                                    style={{
                                        background: `#172554`,
                                        overflow: 'hidden',
                                    }}
                                >
                                    {/* Background for the empty bar */}
                                    <div
                                        className="absolute inset-0 bg-gray-600"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    {/* Filled gradient according to the value */}
                                    <div
                                        className="absolute inset-0"
                                        style={{
                                            background: `#172554`,
                                            width: `${(value / maxValues.advanced[key]) * 100}%`,
                                        }}
                                    />
                                    {/* Display the current value */}
                                    <span className="absolute inset-0 flex items-center justify-center font-bold text-white">
                                    {value}
                                </span>
                                </div>

                                {/* Increment button */}
                                <button
                                    onClick={() =>
                                        setCarState((prevState) => ({
                                            ...prevState,
                                            advanced: {
                                                ...prevState.advanced,
                                                [key]: Math.min(maxValues.advanced[key], prevState.advanced[key] + 1),
                                            },
                                        }))
                                    }
                                    className="px-2 py-1 border border-gray-300 rounded-md ml-2"
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    };


    const renderMaxValues = () => {
        return (
            <>
                <h4 className="font-semibold text-gray-700 mb-2">{translate("psMax")}</h4>

                {gameMode === 'simple' && (
                    <div className="flex items-center mb-2">
                        <span className="w-28 text-gray-600">{translate("vehicle")}</span>
                        <input
                            type="number"
                            value={maxValues.simple.general}
                            onChange={(e) => handleMaxChange('general', Number(e.target.value))}
                            className="border border-gray-300 rounded-md shadow-sm w-16 text-center"
                        />
                    </div>
                )}

                {gameMode === 'advanced' && (
                    <>
                        {Object.keys(maxValues.advanced).map((component) => (
                            <div key={component} className="flex items-center mb-2 space-x-2">
                                <span
                                    className="w-28 text-gray-600">{translate(component)}</span> {/* Adjust width as necessary */}
                                <input
                                    type="text" // We'll use a text input but only accept numeric input
                                    inputMode="numeric" // Ensures numeric keypad on mobile
                                    pattern="\d*" // Allows only digits
                                    value={maxValues.advanced[component] !== undefined ? maxValues.advanced[component] : ''} // Default to empty if undefined
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        // Allow empty input or valid numeric input (prevent non-numeric input)
                                        if (newValue === '' || /^\d*$/.test(newValue)) {
                                            handleMaxChange(component, newValue === '' ? '' : Number(newValue));
                                        }
                                    }}
                                    onBlur={(e) => {
                                        const numValue = Number(e.target.value);
                                        // On blur, if the input is empty or invalid, set it to a default value of 1
                                        if (e.target.value === '' || isNaN(numValue) || numValue < 1) {
                                            handleMaxChange(component, 1); // Set the value to 1 if invalid
                                        }
                                    }}
                                    className="border border-gray-300 rounded-md shadow-sm w-16 text-center"
                                />
                            </div>
                        ))}
                    </>

                )}
            </>
        );
    };

    return (
        <Card className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <CardHeader className="bg-gray-100 p-4">
                <div className="flex items-center mb-4">
                    <img className="object-fill rounded-2xl" src={'banner.png'}/>
                </div>
                <div className="flex items-center"> {/* Remove justify-between to keep items centered */}
                    <h2 className="text-2xl font-bold text-gray-800 flex items-center"> {/* Add flex items-center to the h2 */}
                        <div className={`helmet ${vehicleColor}-helmet rounded-full mr-2`}
                             style={{width: '40px', height: '40px'}}></div>
                        {/* Set a fixed size for the helmet */}
                        {driverName}
                    </h2>

                        <Button onClick={() => setShowSettings(!showSettings)}
                                className="text-gray-500 hover:text-gray-700 ml-auto"> {/* Add ml-auto to push the button to the right */}
                            <Settings size={24}/>
                        </Button>
                    </div>

            </CardHeader>
            <div className="mt-4 border-t border-gray-200 pt-4"></div>
            <CardContent className="p-6">
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-600 mb-2">{translate('gameMode')}</label>
                        <div className="flex items-center">
                            <button
                                onClick={() => setGameMode('simple')}
                                className={`flex-1 py-2 text-center rounded-l-md ${gameMode === 'simple' ? 'bg-blue-950 text-white' : 'bg-gray-200 text-gray-700'} transition`}
                            >
                                {translate('basic')}
                            </button>
                            <button
                                onClick={() => setGameMode('advanced')}
                                className={`flex-1 py-2 text-center rounded-r-md ${gameMode === 'advanced' ? 'bg-blue-950 text-white' : 'bg-gray-200 text-gray-700'} transition`}
                            >
                                {translate('advanced')}
                            </button>
                        </div>
                    </div>

                    <div className="mt-2 border-t border-gray-200"></div>

                    <div>
                        <label className="block text-sm font-medium text-gray-600">{translate("currentGear")}: </label>
                        <div className="mt-2 flex space-x-4 justify-center">
                            {diceColors.filter(color => color !== 'black').map((color, index) => {
                                const {min, max} = diceRanges[color]; // Get the min and max values for the current dice color
                                return (
                                    <div className="flex flex-col items-center">
                                        <button
                                            key={index + 1}
                                            //onClick={() => setSpeed(index + 1)}
                                            onClick={() => changeGear(index + 1)}
                                            className={`w-10 h-10 flex items-center justify-center rounded-full text-white 
                      ${speed === index + 1 ? 'border-4 border-black font-bold' : ''}
                      ${color}-dice`}  // Adding a dice color class dynamically
                                        >
                                            {index + 1}
                                        </button>
                                        <span
                                            className="text-xs text-gray-600 mt-1">{`[${min}-${max}]`}</span> {/* Display min and max */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="mt-2 border-t border-gray-200"></div>

                    {renderCarState()}
                    <div className="mt-2 border-t border-gray-200"></div>
                    <div className="mt-2 flex justify-center space-x-2">
                        <h3 className="block font-medium text-gray-600">{translate("roll")}</h3>
                    </div>
                    {/*<button*/}
                    {/*    onClick={() => rollDice(diceColors[speed - 1])}  // Roll based on selected gear dice*/}
                    {/*    className={`mt-4 text-white py-2 px-4 rounded ${diceColors[speed - 1]}-dice`} // Dynamically set button color*/}
                    {/*>*/}
                    {/*  Roll Dice*/}
                    {/*</button>*/}
                    <div className="mt-2 flex w-full">
                        {/* Left section: Dice buttons (50% width) */}
                        <div className="w-2/3 flex justify-start space-y-2 space-x-2 flex-wrap">
                            {/* Use justify-start to align items to the left */}

                            {/* Black dice button */}
                            <button
                                key={7}
                                onClick={() => rollDice('black')}
                                className={`flex items-center justify-center w-14 h-14 text-white dice ml-2 mt-2
                    dice-black  flex-shrink-0 ${rotatingDice === 'black' ? 'rotate' : ''}`}
                            >
                                {/* Optional content inside dice button */}
                            </button>

                            {/* Other colored dice buttons */}
                            {diceColors.filter(color => color !== 'black').map((color, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => rollDice(color)}
                                    className={`flex items-center justify-center w-14 h-14 text-white dice 
                        dice-${index + 1}  flex-shrink-0 ${rotatingDice === color ? 'rotate' : ''}`}
                                >
                                    {/* Optional content inside dice button */}
                                </button>
                            ))}
                        </div>


                        {/* Right section: Dice result (50% width) */}
                        <div className="w-1/3 flex items-center justify-center">
                            {diceResult && (
                                <div id="dice-result"
                                     className={`die-roll-box ${diceResult.color}-dice w-24 h-24 flex items-center justify-center`}>
                                    {diceResult.result}
                                </div>
                            )}
                        </div>
                    </div>


                    {showSettings && (
                        <div ref={settingsRef} className="mt-4 border-t border-gray-200 pt-4">
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-semibold text-gray-700 mb-2">{translate("settings")}</h3>
                                <button
                                    onClick={() => setShowSettings(false)} // Set show settings to false on click
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Close Settings"
                                >
                                    &times; {/* This represents the X close icon */}
                                </button>
                            </div>
                            <div className="flex items-center mt-4">
                                <label
                                    className="mr-2 text-sm font-medium text-gray-600">{translate("language")}</label>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={() => setLanguage('en')}
                                        className={`flex items-center p-2 border rounded-md transition ${
                                            language === 'en' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                        }`}
                                        aria-label="English"
                                    >
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                                            alt="US Flag" className="w-6 h-4 mr-1"/>
                                    </button>
                                    <button
                                        onClick={() => setLanguage('fr')}
                                        className={`flex items-center p-2 border rounded-md transition ${
                                            language === 'fr' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                        }`}
                                        aria-label="French"
                                    >
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
                                            alt="French Flag" className="w-6 h-4 mr-1"/>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-4 border-t border-gray-200 pt-4"></div>
                            <div className="flex items-center mb-2">
                                <label htmlFor="driverName"
                                       className="mr-2 text-gray-600">{translate("DriverName")}</label>
                                <input
                                    id="driverName"
                                    type="text"
                                    value={driverName}
                                    onChange={(e) => setDriverName(e.target.value)} // Update state on input change
                                    className="border border-gray-300 rounded px-2 py-1"
                                />
                            </div>
                            <div className="flex items-center mb-2">
                                <label className="mr-2 text-gray-600">{translate("carColor")}</label>
                                <div className="flex space-x-2">
                                    {vehicleColors.map(color => (
                                        <div
                                            key={color}
                                            className={`helmet-30 ${color}-helmet rounded-full cursor-pointer w-10 h-10 flex items-center justify-center border-2 ${vehicleColor === color ? 'border-black' : 'border-transparent'}`}
                                            onClick={() => setVehicleColor(color)} // Update the vehicle color on click
                                            title={color.charAt(0).toUpperCase() + color.slice(1)} // Tooltip for color name
                                        >
                                            {/* You can add a checkmark or some indicator for the selected color here if desired */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-4 border-t border-gray-200 pt-4"></div>
                            <div className="flex gap-5 mb-2">
                                {/* Switch for 7th Gear */}
                                <div className="flex items-center">
                                    <Switch
                                        checked={useSeventhGear}
                                        onChange={(e) => setUseSeventhGear(e.target.checked)}
                                        className="mr-2"
                                    />
                                    <span className="ml-2 text-gray-600">{translate("use7")}</span>
                                </div>

                                {/* Switch for Audio */}
                                <div className="flex items-center">
                                    <Switch
                                        checked={audioOn}
                                        onChange={(e) => setAudioOn(e.target.checked)}
                                        className="mr-2"
                                    />
                                    <span
                                        className="ml-2 text-gray-600">{translate("audio")}</span> {/* Use your translation key for audio */}
                                </div>
                            </div>
                            <div className="mt-4 border-t border-gray-200 pt-4"></div>
                            <div className="space-y-2">
                                <h4 className="font-semibold text-gray-700 mb-2">{translate(("dicesVal"))}</h4>
                                {Object.entries(diceRanges).map(([color, range]) => (
                                    <div key={color} className="flex items-center space-x-2">
                            <span
                                className="w-16 text-gray-600">{translate(color)}</span>
                                        <Input
                                            type="number"
                                            value={range.min}
                                            onChange={(e) => setDiceRanges(prev => ({
                                                ...prev,
                                                [color]: {...prev[color], min: parseInt(e.target.value)}
                                            }))}
                                            className="w-16 border-gray-300 rounded-md shadow-sm text-center"
                                        />
                                        <span>{translate('to')}</span>
                                        <Input
                                            type="number"
                                            value={range.max}
                                            onChange={(e) => setDiceRanges(prev => ({
                                                ...prev,
                                                [color]: {...prev[color], max: parseInt(e.target.value)}
                                            }))}
                                            className="w-16 border-gray-300 rounded-md shadow-sm text-center"
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="mt-4 border-t border-gray-200 pt-4"></div>
                            {renderMaxValues()}
                            <button
                                onClick={resetApp}
                                className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                            >
                                {translate("reset")}
                            </button>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default FormulaPlayerCard;
